/**
  * @copyright  Copyright © Leviathan Studios, Licensed under MIT
  * @author     Grey Crane <gmc31886@gmail.com>
  */

@tailwind base;
@tailwind components;
@tailwind utilities;

*::selection {
  background: #d10d0d;
  color: white
}
*::-moz-selection {
  background: #d10d0d;
  color: white
}

#root {
  min-height: 100vh;
}
